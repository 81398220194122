import React from "react";
import Rekormuri2024 from "./1.png";
import Rekormuri2022 from "./2.png";
import Awards from "./3.png";
import Kelulusan2024 from "./kelulusan-no-bg.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const Muri = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Mengaktifkan autoplay
    autoplaySpeed: 3000, // Kecepatan autoplay dalam milidetik
    arrows: true, // Menghilangkan tanda panah
  };

  return (
    <div className=" container-fluid py-5 bg-danger">
      <div className="container">
        <div className="row">
          {/* Left Side */}
          <div className="col col-lg-6">
            <div className="row mb-5">
              <h1 className="text-start text-white mb-5 mt-3">
                Tentang Ganesha Operation
              </h1>
              <div className="col-lg-6 mb-4 mb-lg-0">
                <h3 className="text-white fw-bold">50.000+</h3>
                <p className="text-white">
                  Ganesha Operation adalah bimbingan belajar tatap muka berbasis
                  teknologi <i>online</i> yang telah berhasil mengantarkan
                  50.000 lebih kelulusan siswa ke PTN dan PT Kedinasan, 2.500
                  lebih di antaranya diterima di kedokteran pada tahun 2024 dan
                  angka tersebut terus meningkat setiap tahunnya.
                </p>
              </div>

              <div className="col-lg-6">
                <h3 className="text-white fw-bold">Award winner</h3>
                <p className="text-white">
                  Sebagai bukti bahwa Ganesha Operation merupakan bimbingan
                  belajar terbaik dan terbesar di Indonesia, GO telah berhasil
                  meraih berbagai prestasi, di antaranya GO dianugerahi 4 rekor
                  MURI pada tahun 2022 dan tahun 2024.
                </p>
              </div>
            </div>
          </div>

          {/* Right Side */}
          <siv className="col col-lg-6">
            <div className="w-100 h-100">
              <Slider {...settings} className="card__container--inner">
                <div className="carousel-slide">
                  <img
                    className="murigo"
                    src={Rekormuri2022}
                    alt="Muri ganesha operation 2022"
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    className="murigo"
                    src={Rekormuri2024}
                    alt="Muri ganesha operation 2024"
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    className="murigo"
                    src={Awards}
                    alt="Awards ganesha operation 2022"
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    className="murigo"
                    src={Kelulusan2024}
                    alt="Muri ganesha operation 2024"
                  />
                </div>
              </Slider>
            </div>
          </siv>
        </div>
      </div>
    </div>
  );
};

export default Muri;
