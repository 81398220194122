import React, { useEffect, useState } from "react";
// import Countdowntimer from '../../CountdownTimer/Index';
import "./index.css";
import SI from "./kompas.png";
import axios from "axios";
// import SI from "./kelulusan-bg.png";
const Modaldiskonagt = (props) => {
  // const Countdown = Countdowntimer(
  //   '2023-08-19T23:59:00',
  //   '2023-08-17T00:01:00'
  // );

  // const [copied, setCopied] = useState(false);

  // useEffect(() => {
  //   if (Countdown.condition === false) {
  //     setShow(false);
  //   } else setShow(true);
  // }, [Countdown.condition]);
  // console.log('show :', show);
  // console.log('kondisi :', Countdown.condition);
  // console.log('ini countdown', Countdown);

  // const buttonVariants = {
  //   initial: { scale: 1 },
  //   copied: { scale: 1 },
  //   success: { scale: 1.2 },
  // };

  // const copiedText = () => {
  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 600);
  // };

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 1000,
  //   autoplaySpeed: 3000,
  //   autoplay: true,
  //   initialSlide: 0,
  //   pauseOnHover: true,
  // };

  const [isAlert, setIsAlert] = useState(false);

  useEffect(() => {
    const startDateAlert = new Date("2024-07-15").getTime();
    const endDateAlert = new Date("2024-11-10").getTime();
    const now = new Date().getTime();
    const periodAlert = now >= startDateAlert && now <= endDateAlert;

    setIsAlert(periodAlert);
    console.log("periodAlert :", periodAlert);
  }, []);

  // saved click to database
  const handleClick = async () => {
    try {
      // const response = await axios.post(
      //   `https://ganeshaoperation.com/nodejs/click/${page}`,
      //   {}
      // );

      // if (response.status !== 200) {
      //   throw new Error("Gagal menambah klik");
      // }

      // Redirect after successful response
      window.open("https://komp.as/kompasxgo", "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* {Countdown.condition && show && ( */}
      <>
        <div
          className={`${isAlert === true ? "diskonagt-bg" : "diskonagt-bg-noal"
            }`}
        ></div>

        <div className="diskonagt-container">
          <div className="diskonagt-wrapper position-relative">
            <div
              data-bs-title="Default tooltip"
              onClick={props.close}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                zIndex: 99,
              }}
              className="text-center fw-bold position-absolute  start-100 translate-middle bg-warning rounded-circle"
            >
              X
            </div>
            {/* <a
              href="https://ganeshaoperation.com/tobk_nasional_go/"
              target="_blank"
            > */}
            <img
              style={{ width: "100%" }}
              src={SI}
              className="rounded-3"
              alt="promo ganesha opeartion"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClick();
              }}
            />
            {/* </a> */}
            {/* <img
              style={{ width: "100%" }}
              src={SI}
              className="rounded-3"
              alt="promo ganesha opeartion"
            /> */}
          </div>
        </div>
      </>
    </>
  );
};

export default Modaldiskonagt;
